import { Component, Vue } from 'vue-property-decorator';
import { Logger } from 'fsts';
import { Route } from 'vue-router';
import { namespace } from 'vuex-class';
// TODO: (ED-271) use next model https://github.com/dev4yougmbh/easy-docs-services/blob/dev/Services/Backend/Backend.DataAccessPostgreSQL/DataAccess/DataObjects/ProcessList.cs
import ProcessEditDialog from '@/views/menu/rightMainMenu/menu-process/process-edit-dialog/process-edit-dialog.vue';
const logger = new Logger('processes');
const processModule = namespace('process');
const authModule = namespace('auth');

@Component({
  components: { ProcessEditDialog },
})
export default class ProcessesView extends Vue {
  @authModule.Getter('ViewAllProcessListsAllowed')
  private ViewAllProcessListsAllowed!: boolean;
  @authModule.Getter('ViewProcessListsAllowed')
  private ViewProcessListsAllowed!: boolean;
  @authModule.Getter('EditProcessListsAllowed')
  private EditProcessListsAllowed!: boolean;
  @authModule.Getter('DeleteProcessListsAllowed')
  private DeleteProcessListsAllowed!: boolean;
  @authModule.Getter('isOwner')
  private isOwner!: boolean;
  get processesItems() {
    return [
      {
        process: 'test',
        manyFields: `VSSZZZ5FZH6529004
MUS529004
1000003
VFW BESTAND`,
        headline: 'dfg',
        operation: 'Betrieb 01',
        employees: 'kedas@dev4you.gmbh',
        attachmentDate: '12.08.2021',
        closingDate: '08.10.2021',
        trafficLight: 'red',
      },
    ];
  }

  private processes: any = {
    searchParams: {},
  };

  private itemsPerPageOptions: number[] = [5, 10, 15, 25];

  get headers() {
    const headers: {
      text: string | any;
      value: string;
      class?: string;
      sortable?: boolean;
      width?: string;
    }[] = [
      { text: this.$t('process'), value: 'process' },
      { text: this.$t('serial_number_and_other_fields'), value: 'manyFields', sortable: false },
      { text: this.$t('vapvpa'), value: 'vapvpa', class: 'vertical-column' },
      { text: this.$t('headline'), value: 'headline', sortable: false },
      { text: this.$t('operation'), value: 'operation' },
      { text: this.$t('employees'), value: 'employees', sortable: false },
      { text: this.$t('attachment_date'), value: 'attachmentDate' },
      { text: this.$t('closing_date'), value: 'closingDate' },
      { text: this.$t('traffic_light'), value: 'trafficLight' },
    ];
    return headers;
  }
  //#region edit dialog
  processEditDialog: {
    isLoading: boolean;
    isVisible: boolean;
    canDelete: boolean;
    canProcess: boolean;
    canUpdate: boolean;
    model: { id: string };
  } = {
    isLoading: false,
    isVisible: false,
    canDelete: false,
    canProcess: false,
    canUpdate: false,
    model: { id: '' },
  };
  //TODO implement close
  async processEditDialogOnClose() {
    this.processEditDialog.isVisible = false;
  }
  //TODO implement process
  async processEditDialogOnProcess(process: any) {
    this.processEditDialog.isLoading = true;
    this.processEditDialog.isLoading = false;
    this.processEditDialog.isVisible = false;
  }
  //TODO implement edit
  async processEditDialogOnUpdate(process: any) {
    this.processEditDialog.isLoading = true;
    this.processEditDialog.isLoading = false;
    this.processEditDialog.isVisible = false;
  }
  //TODO implement delete
  async processEditDialogOnDelete(process: any) {
    this.processEditDialog.isLoading = true;
    this.processEditDialog.isLoading = false;
    this.processEditDialog.isVisible = false;
  }
  //TODO implement add
  onClickProcessAdd(item: any) {
    this.processEditDialog.model = { id: '' };
    this.processEditDialog.isVisible = true;
  }
  async onClickProcessRow(item: any) {
    this.processEditDialog.model.id = item.id;
    this.processEditDialog.canProcess = this.ViewProcessListsAllowed || this.isOwner;
    this.processEditDialog.canUpdate = this.EditProcessListsAllowed || this.isOwner;
    this.processEditDialog.canDelete = this.DeleteProcessListsAllowed || this.isOwner;
    this.processEditDialog.isVisible = true;
  }
  //#endregion
}
